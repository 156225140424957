import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { FaInfoCircle } from 'react-icons/fa'
import { usePaymentInputs } from 'react-payment-inputs'

import useFakeLoader from '../../hooks/useFakeLoader'
import { toCurrency } from '../../utils/format'
import CancellationInformation from '../CancellationInformation'
import CreditCardIcon from '../CreditCardIcon'
import FlightDetails from '../FlightDetails'
import {
  AddressInfo,
  FlightInfo,
  FlightPrice,
  PassengerInfo,
  PaymentInfo,
  PaymentPageInfo,
} from '../Flights/Flights'
import Input from '../Input'
import Label from '../Label'
import Loader from '../Loader'
import Steps from '../Steps'
import { useBookMutation } from './mutation.generated'

type PaymentFormFields = PaymentInfo & {
  accecptTerms: boolean
}

type PaymentFormProps = {
  contactAddress: AddressInfo
  flight: FlightInfo
  invoiceAddress: AddressInfo
  onBack: () => void
  onContinue: (pageInfo: PaymentPageInfo) => void
  passengers: PassengerInfo[]
  price: FlightPrice
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  contactAddress,
  flight,
  invoiceAddress,
  onBack,
  onContinue,
  passengers,
  price,
}) => {
  const [book] = useBookMutation()
  const toast = useToast()

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<PaymentFormFields>()

  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs()

  const { isLoading } = useFakeLoader()

  const handleCancel = () => {
    onBack()
  }

  const handleConfirm = async (values: PaymentFormFields) => {
    try {
      const { data: bookFlight } = await book({
        variables: {
          leg: flight.id,
          cardCVC: +values.cvc,
          cardExpirationDate: values.expirationDate,
          cardholder: values.cardholder,
          cardnumber: values.cardnumber,
          subtotal: price.subtotal,
          taxes: price.taxes,
          luxuryTaxes: price.luxuryTax,
          serviceFee: price.serviceFee,
          total: price.total,
          contactAddress: {
            ...contactAddress,
          },
          invoiceAddress: {
            ...invoiceAddress,
          },
          passenger: passengers.map((passenger, index) => ({
            birthdate: passenger.birthdate,
            birthplace: passenger.birthplace,
            firstname: passenger.firstname,
            lastname: passenger.lastname,
            middlename: passenger.middlename,
            nationality: passenger.nationality,
            number: index + 1,
            passExpirationDate: passenger.passExpiryDate,
            passIssueDate: passenger.passIssueDate,
            passnumber: passenger.passnumber,
          })),
        },
      })

      if (bookFlight && bookFlight.bookFlight) {
        onContinue({
          bookingNumber: bookFlight.bookFlight.bookingNumber,
          payment: { ...values },
        })
      }
    } catch (err) {
      console.log(err)
      toast({
        title: 'Error occurred.',
        description:
          'We are sorry, but we cannot book the flight right now. Please try again or contact our sales team if the problem still occurs.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(handleConfirm)}>
      <Box pb={8} pt={16}>
        <Steps
          steps={[
            { name: 'Passenger details', status: 'complete' },
            // { name: 'Contact details', status: 'upcoming' },
            { name: 'Payment details', status: 'current' },
            { name: 'Confirmation', status: 'upcoming' },
          ]}
        />
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <FlightDetails
            arrivalTime={flight.arrivalTime}
            date={flight.date}
            departure={flight.departure}
            destination={flight.destination}
            time={flight.departureTime}
          />
          <Box
            display="grid"
            gap={8}
            gridTemplateColumns={{
              base: 'repeat(1, minmax(0, 1fr))',
              sm: 'repeat(2, minmax(0, 1fr))',
            }}
            py={16}
          >
            <Box>
              <Box borderBottomWidth={0} pb={5}>
                <Heading as="h2" size="sm">
                  Payment information
                </Heading>
                <Text color="gray.500" fontSize="sm" mt={1}>
                  We will not charge your credit card until check-in.
                </Text>
              </Box>

              <Stack direction="column" spacing={4}>
                <FormControl isInvalid={errors.cardholder !== undefined}>
                  <Label htmlFor="cardholder">Card holder</Label>
                  <Input
                    autoComplete="off"
                    id="cardholder"
                    {...register('cardholder', {
                      required: 'This is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.cardholder && errors.cardholder.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.cardnumber !== undefined}>
                  <Label htmlFor="cardnumber">Card number</Label>
                  <Controller
                    name="cardnumber"
                    control={control}
                    rules={{
                      required: 'This is required',

                      validate: {
                        validFormat: () =>
                          meta.erroredInputs.cardNumber
                            ? 'Invalid format'
                            : undefined,
                        validType: () =>
                          meta.cardType.type !== 'mastercard' &&
                          meta.cardType.type !== 'visa'
                            ? 'Only Visa or Mastercard are accepted'
                            : undefined,
                      },
                    }}
                    render={({ field }) => (
                      <InputGroup>
                        <Input
                          {...field}
                          {...getCardNumberProps({
                            onBlur: field.onBlur,
                            onChange: field.onChange,
                          })}
                          autoComplete="off"
                          id="cardnumber"
                          placeholder=""
                        />
                        <InputRightElement pointerEvents="none">
                          <CreditCardIcon cardType={meta.cardType?.type} />
                        </InputRightElement>
                      </InputGroup>
                    )}
                  />
                  <FormErrorMessage>
                    {errors.cardnumber && errors.cardnumber.message}
                  </FormErrorMessage>
                </FormControl>

                <Box display="grid" gap={4} gridTemplateColumns="2fr 1fr">
                  <FormControl isInvalid={errors.expirationDate !== undefined}>
                    <Label htmlFor="expirationDate">
                      Expiration date (MM/YY)
                    </Label>
                    <Controller
                      name="expirationDate"
                      control={control}
                      rules={{
                        required: 'This is required',
                        validate: () => meta.erroredInputs.expiryDate,
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          {...getExpiryDateProps({
                            onBlur: field.onBlur,
                            onChange: field.onChange,
                          })}
                          autoComplete="off"
                          id="expirationDate"
                          placeholder=""
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.expirationDate && errors.expirationDate.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.cvc !== undefined}>
                    <Label htmlFor="cvc">CVC</Label>
                    <Controller
                      name="cvc"
                      control={control}
                      rules={{
                        required: 'This is required',
                        validate: () => meta.erroredInputs.cvc,
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          {...getCVCProps({
                            onBlur: field.onBlur,
                            onChange: field.onChange,
                          })}
                          autoComplete="off"
                          id="cvc"
                          placeholder=""
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.cvc && errors.cvc.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Stack>
              <Text
                alignItems="center"
                color="gray.500"
                display="flex"
                fontSize="sm"
                gap={2}
                mt={4}
              >
                <Icon as={FaInfoCircle} color="brand.400" w={4} h={4} /> Only
                Visa or Mastercard are currently accepeted.
              </Text>
            </Box>
            <Box display="flex" flexDirection="column" gap={4}>
              <VStack
                alignItems="stretch"
                bgColor="gray.100"
                rounded="xl"
                p={4}
              >
                {passengers.map((passenger, index) => (
                  <PassengerRow
                    key={index}
                    firstname={passenger?.firstname}
                    lastname={passenger?.lastname}
                    passExpirationDate={passenger?.passExpiryDate}
                    passnumber={passenger?.passnumber}
                    middlename={passenger?.middlename}
                    flightprice={flight.price.net}
                  />
                ))}
                <Divider />
                <Box display="flex" justifyContent="space-between">
                  <Text>Subtotal</Text>
                  <Text>{toCurrency(price.subtotal)}</Text>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Text>Taxes</Text>
                  <Text>{toCurrency(price.taxes)}</Text>
                </Box>

                {price.luxuryTax !== 0 ? (
                  <Box display="flex" justifyContent="space-between">
                    <Text>Luxury Tax (Italy)</Text>
                    <Text>{toCurrency(price.luxuryTax)}</Text>
                  </Box>
                ) : null}

                <Divider />

                <Box display="flex" justifyContent="space-between">
                  <Text>Service Fee</Text>
                  <Text>{toCurrency(price.serviceFee)}</Text>
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  paddingTop={2}
                >
                  <Text fontWeight="semibold">Total</Text>
                  <Text fontWeight="semibold">{toCurrency(price.total)}</Text>
                </Box>
              </VStack>
              <Box>
                <Box py={4}>
                  <FormControl isInvalid={errors.accecptTerms !== undefined}>
                    <Checkbox
                      colorScheme="brand"
                      {...register('accecptTerms', {
                        required:
                          'You have to accept our terms and conditions.',
                      })}
                    >
                      Accept our{' '}
                      <Box
                        as="a"
                        color="brand.500"
                        href={require('./AGB.pdf')}
                        target="_blank"
                        rel="noreferrer"
                        _hover={{ textDecoration: 'underline' }}
                      >
                        terms and conditions
                      </Box>
                    </Checkbox>
                    <FormErrorMessage>
                      {errors.accecptTerms && errors.accecptTerms.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <Button
                  colorScheme="brand"
                  isLoading={isSubmitting}
                  loadingText="Booking"
                  type="submit"
                  width="full"
                >
                  Book now
                </Button>
              </Box>
            </Box>
          </Box>
          <Box py={4}>
            <CancellationInformation />
          </Box>
          <Box>
            <Button
              colorScheme="brand"
              onClick={handleCancel}
              variant="outline"
            >
              Go back
            </Button>
          </Box>
        </>
      )}
    </form>
  )
}

type PassengerRowProps = {
  firstname: string
  middlename?: string
  lastname: string
  passnumber: string
  passExpirationDate: string
  flightprice: number
}

const PassengerRow: React.FC<PassengerRowProps> = ({
  firstname,
  lastname,
  passExpirationDate,
  passnumber,
  middlename,
  flightprice,
}) => {
  return (
    <Box display="flex" gap={4} justifyContent="space-between" width="full">
      <Box>
        <Text>
          {firstname} {middlename} {lastname}
        </Text>
        <Text color="gray.500" fontSize="sm">
          Pass: {passnumber} (Expiration date: {passExpirationDate})
        </Text>
      </Box>
      <Text>{toCurrency(flightprice)}</Text>
    </Box>
  )
}

export default PaymentForm
